.front_guide {
	text-align: center;
	background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px),
	repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px),
	linear-gradient(135deg, rgb(251, 168, 16) 50%, rgb(251, 222, 55));	
}

	.guide_media {
		line-height: 0;

		.media_wrapper {
			display: inline-block;
			position: relative;
			overflow: hidden;
			a {
				display: block;
				border: 5px solid $color-text;
				img { transition: transform 300ms; }
				&:hover {
					img { transform: scale(1.3); transition: transform 300ms; }
				}
			}
			span {
				display: inline-block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				padding: 10px;
				@include font-size(15);
				font-weight: $body-weight-bold;
				line-height: 1.2;
				background-color: rgba(black,0.8);
				color: white;
			}
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-up(lg) {
			.media_wrapper span {
				@include font-size(20);
			}
		}

	}

