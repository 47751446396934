.front_fold {
	line-height: 0;
	background-image: 
		linear-gradient(to bottom, rgba(white,0.85), rgba(white,0.8)),
		url("/_/images/layout/jumbo-bg-1.jpg");
		background-position: center bottom;
	background-size: cover;

	@include media-breakpoint-up(md) {
		background-image: url("/_/images/layout/jumbo-bg-1.jpg");
	}

	.block_banner {
		.wrap+.wrap { margin-top: 15px; }
		@include media-breakpoint-up(md) {
			border-bottom: 5px solid $color-secondary;
			background-color: rgba(white,0.85);
			.wrap+.wrap { margin-top: 0; }
			.row { align-items: center; }
			.wrap { @include make-col(3,12); }
			.banner_branding { @include make-col(6,12); order: 2; padding: 0; }
			.banner_date { order: 1; text-align: left; }
			.banner_weather { order: 3; text-align: right; }
		}
	}

	.banner_navigation {
		@include media-breakpoint-up(md) { margin-top: 180px; }
		@include media-breakpoint-up(lg) { margin-top: 280px; }
		@include media-breakpoint-up(xl) { margin-top: 320px; }
	}

}

.block_banner {
	line-height: 1;
	text-align: center;
}

.banner_button { text-align: left; }


	.branding-logo {
		display: block;
		margin: 0 auto;
		max-width: 300px;
		@include media-breakpoint-up(lg) {
			max-width: 360px;
		}
	}

//.banner_date {}

	.info_date {
		font-weight: $body-weight-bold;
		letter-spacing: -0.0375em;
		.day { display: block; }
		.date { display: block; }
		.current-time b {
			@include font-size(24);
			font-weight: $body-weight-bolder;
		}
	}

.banner_weather {}

	.info_weather {
		.to_weather { margin-bottom: 5px; }
		.temp {
			display: block;
			@include font-size(32);
			font-weight: $body-weight-bolder;
			sup { @include font-size(18); font-weight: $body-weight-bold; }
		}
		.accuweather {
			display: inline-block;
			max-width: 150px;
			padding: 5px;
			@include font-size(13);
			text-decoration: none;
			color: $color-text !important;
		}
	}


.banner_navigation {
	margin-top: -1px;
	padding: 0;
	line-height: 0;

	@include media-breakpoint-up(md) {
		margin-top: 0;
		background-color: $color-secondary;
		.nav-main {
			text-align: center;

			>.nav-item {
				display: inline-block;
				&:not(:last-child) { border-right: 1px solid white; }
				&:not(:first-child) { border-left: 1px solid white; }
				>.nav-link { padding: 20px 30px; }
			}
		}

		.dropdown-nav {
			position: absolute;
			min-width: 270px;
			padding: 15px;

			.nav-item:not(:last-child) {
				border-bottom: 1px dotted #bbbbbb;
			}

			.nav-link {
				padding: 10px;
				letter-spacing: -0.0325em;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.nav-main {
			>.nav-item {
				display: block;
				float: left;
				width: percentage($number: 1/5);
			}
		}
	}

}
