.page_title {
	border-top: 2px solid white;
	color: white;

	background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px),
	repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px),
	linear-gradient(135deg, rgb(251, 168, 16) 50%, rgb(251, 222, 55));

	@include media-breakpoint-up(md) { padding: 120px 15px 15px; }
	@include media-breakpoint-up(lg) { padding-top: 180px; }

}

	.page-title {
		margin: 0;
		text-shadow: 0 1px 1px rgba(black,0.4);
	}
