$mobile-bg: 					$color-secondary;
$main-bg:						transparent;

$mobile-link-bg: 				transparent;
$main-link-bg: 					$color-secondary;

$mobile-link-color:				white;
$main-link-color:				white;

$mobile-hover-bg: 				$color-primary;
$main-hover-bg: 				$color-primary;

$mobile-hover-color: 			white;
$main-hover-color: 				white;

$mobile-active-bg: 				white;
$main-active-bg: 				$body-bg;

$mobile-active-color: 			$color-primary;
$main-active-color: 			$color-primary;

$mobile-link-open:				$mobile-hover-bg;
$main-link-open:				$main-hover-bg;

$mobile-dropdown-bg:			rgba(white,0.1);
$main-dropdown-bg:				white;

$main-dropdown-link-bg:			transparent;
$main-dropdown-link-color: 		$color-text;
$main-dropdown-hover-bg:		transparent;
$main-dropdown-hover-color:		$color-primary;
$main-dropdown-active-bg: 		white;
$main-dropdown-active-color: 	$color-primary;

@mixin offcanvas {
	@include media-breakpoint-down(sm) {
		display: none;
		&.in { display: block; }
	}
}

.nav_main  {

	@include offcanvas;
	position: fixed;
	z-index: 10;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 80%;
	max-width: 300px;
	height: 100%;
	padding: 20px;
	text-align: left;
	background-color: $mobile-bg;
	color: white;

	.nav-item {
		&.active .nav-link {
			font-weight: $body-weight-bolder;
			letter-spacing: -0.0325em;
			text-shadow: none !important;
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			.caret,
			.caret-push { width: 0; }
			&:hover, &:focus {
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}
		&.open > .nav-link {
			background-color: $mobile-link-open;
			color: $mobile-hover-color;
			.caret { fill: $mobile-hover-color; }
		}
	}	

	.nav-link {
 		padding: 15px;
		font-weight: $body-weight-bold;
		background-color: $mobile-link-bg;
		color: $mobile-link-color;
		&:hover, &:focus, &:active {
			outline: none;
			text-shadow: 0 1px 1px rgba(black, 0.5);
			background-color: $mobile-hover-bg;
			color: $mobile-hover-color;
			.caret,
			.caret-push { fill: $mobile-hover-color; }
		}
	}

	.caret { fill: $mobile-link-color; }
	.caret-push { fill: $mobile-hover-color; }

	.dropdown-nav {
		padding: 0;
		text-align: left;
 		background-color: $mobile-dropdown-bg;
	}

	hr {
		margin: 15px 0;
		background-color: white;
	}

	@include media-breakpoint-up(md) {
		position: relative;
		top: auto; right: auto;
		bottom: auto; left: auto;
		overflow-y: visible;
		width: 100%;
		max-width: none;
		height: auto;
		padding: 0;
		border-right: none;
		box-shadow: none;
		background-color: $main-bg;
		
		hr { display: none; }

		.nav-item {
			&.active .nav-link {
				background: $main-active-bg;
				b { font-weight: $body-weight-bolder; }
				&:hover, &:focus {
					background: $main-active-bg;
					//color: $main-active-color;
				}
			}
			&.open > .nav-link {
				background-color: $main-link-open;
			}
		}	

		.nav-link {
			border-color: darken($main-link-bg, 10%);
			background-color: $main-link-bg;
			color: $main-link-color;
			&:hover,
			&:focus {
				background-color: $main-hover-bg;
				color: $main-hover-color;
			}
		}

		.dropdown-nav {
			border: 1px solid #dddddd;
			background-color: $main-dropdown-bg;
			.nav-item.active .nav-link {
				background-color: $main-dropdown-active-bg !important;
				color: $main-dropdown-active-color !important;
			}
			.nav-link {
				background-color: $main-dropdown-link-bg;
				color: $main-dropdown-link-color;
				&:hover {
					background-color: $main-dropdown-hover-bg;
					color: $main-dropdown-hover-color;
					text-shadow: none !important;
				}
				.caret-push {
					fill: $main-dropdown-hover-color;
				}
			}
		}

	}

}

.mobile-toggle {
	display: inline-block;
	padding: 5px 15px;
	color: white !important;
	text-decoration: none;
	.icon-close {
		margin-right: 5px;
		border-radius: 4px;
		width: 24px; height: 24px;
		padding: 5px;
		background-color: $color-danger;
		fill: white;
	}
	@include media-breakpoint-up(md) {
		display: none;
	}
}