.nav {
	@extend %list_unstyled;
	margin: 0;
	padding: 0;
}

	.nav-item {
		box-sizing: border-box;
		list-style-type: none;

		&.active .nav-link {
			cursor: default;
			&:hover { cursor: default; }
		}

		&.open {
			.dropdown-nav {
				display: block;
				height: auto;
			}
			.caret {
				transform: rotate(180deg);
			}
		}

	}

		.nav-link {
			transition: all 400ms;
			display: block;
			padding: 12px 15px;
			font-weight: $body-weight-bold;
			line-height: 1em; 
			text-decoration: none;
			&:hover,
			&:focus {
				transition: all 400ms;
				outline: none;
				.caret-push {
					width: 0.75em;
					transition: width 400ms;
				}
				.caret { transition: fill 400ms; }
			}
		}

			.caret,
			.caret-push {
				display: inline-block;
				position: relative;
				vertical-align: baseline;
				width: 0.75rem;
				height: 0.75rem;
			}

			.caret { transition: fill 400ms; }

			.caret-push {
				transform: rotate(-90deg);
				transition: width 400ms;
				width: 0;
			}

		.dropdown-nav {
			transition: all ease-in-out 300ms;
			display: none;
			position: relative;
			z-index: 10;
			margin: 0;
			.nav-item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
			}
		}



