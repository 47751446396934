@mixin widget-color($color: #333) {
	border-color: $color;
	.widget-title { color: $color; }
	//a:not(.btn) { color: $color; }
}

.widget {
	margin-bottom: 15px;
	padding: 20px 20px 25px;
	@include font-size(16);
	line-height: 1.275em;
	border-radius: 8px;
	border: 2px solid #ccc;
	background-color: $white;

	&.widget-primary { @include widget-color($color-primary); }
	&.widget-secondary { @include widget-color($color-secondary); }
	&.widget-highlight { @include widget-color($color-highlight); }
	&.widget-accent { @include widget-color($color-accent); }

	> *:first-child { margin-top: 0; }

	> *:last-child { margin-bottom: 0; }

}

	.widget-title {
		margin: 0 0 15px;
		letter-spacing: -0.0375em;
	}

	.widget-links {
		li { line-height: 1em;}
		li:not(:last-child) {
			border-bottom: 1px dotted #bbbbbb;
		}
		a {
			display: block;
			padding: 10px;
			@include font-size(16);
			font-weight: $body-weight-bold;
			text-decoration: none !important;
			em {
				display: block;
				margin-top: 5px;
				@include font-size(13);
				font-style: normal;
				color: $color-text;
			}
			&:hover {
				background-color: theme(accent, base);
				color: white;
			}
		}
	}