@import "variables";
@import "functions";
@import "mixins";
@import "placeholders";
@import "reset";
@import "grid";

@import "components/global";
@import "components/typography";
@import "components/links";
@import "components/lists";
@import "components/icons"; 
@import "components/images"; 
@import "components/tables";
@import "components/embed";

//@import "modules/alerts/alert";
@import "modules/buttons/btn";
	@import "modules/buttons/btn_close";
	@import "modules/buttons/btn_mobile";
@import "modules/contact/contact";
@import "modules/copyright/copyright";
@import "modules/events/events";
@import "modules/forms/form";
	@import "modules/forms/form_contact";
	@import "modules/forms/form_register";
@import "modules/listings/p_listings";
@import "modules/listings/f_listings";
@import "modules/logo/logo";
@import "modules/map/map";
@import "modules/navigation/nav";
	@import "modules/navigation/nav_main";
	//@import "modules/navigation/nav_directory";
	//@import "modules/navigation/nav_mobile";
@import "modules/slideshows/slick";
	@import "modules/slideshows/slick-theme";
	@import "modules/slideshows/slideshow";
@import "modules/socials/socials";
@import "modules/videos/video";
@import "modules/weather/weather";
@import "modules/widgets/widget";
	@import "modules/widgets/widget-menu";
	@import "modules/widgets/widget-review";

@import "templates/block";
@import "templates/block_banner";


.update {

	&_block {
		background-color: white;
		text-align: center;
		h2, h3 {
			margin: 0;
		}
	}

	&_title {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #ccc;
	}

	&-title,
	&-content {
		margin-bottom: 15px;
	}

	&_card {
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			> div { 
				width: 50%; 
				padding: 30px;
			}
		}
		@include media-breakpoint-up(xl) {
			justify-content: space-between;
			text-align: left;
			.update-title {
				width: 480px;
				min-width: 480px;
				max-width: 480px;
			}
			.update-content {
				width: 100%;
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				align-items: center;
			}
			.update-convert {
				padding-top: 0;
			}
		}
	}

	&-content {
		@include media-breakpoint-up(xl) {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	&-convert {
		padding-top: 25px;
	}

		&-list {
			@include media-breakpoint-down(xs) {
				li:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			@include media-breakpoint-up(sm) {
				@include list-inline;
				li:not(:last-child) { margin-right: 15px; }
			}
		}

}

@import "templates/block_page_title";
@import "templates/block_content";
	@import "templates/main";
	@import "templates/sidebar";
@import "templates/block_site_info";
@import "templates/front_jumbo";
@import "templates/front_welcome";
@import "templates/front_guide";
@import "components/utilities";
