@mixin btn-solid($color: $color-link) {
	border-color: $color;
	background-color: transparent;
	color: $color;
	&:hover {
		background-color: $color;
		color: $white;
	}
}

.btn {

	@include transition(all 200ms);
	padding: 8px 20px;
	font-weight: $body-weight-bold;
	text-decoration: none;
	border: 2px solid theme(primary, base);
	border-radius: 60px;
	background-color: $body-bg;

	&:hover {
		@include transition(all 200ms);
		background-color: theme(primary, base);
		color: $white;
		cursor: pointer;
	}

	&.wbg-fix { background: $white; }

	&.btn-primary { @include btn-solid($color-primary); }
	&.btn-secondary { @include btn-solid($color-secondary); }
	&.btn-highlight { @include btn-solid($color-highlight); }
	&.btn-accent { @include btn-solid($color-accent); }
	&.btn-success { @include btn-solid($color-success); }
	&.btn-danger { @include btn-solid($color-danger); }
	&.btn-warning { @include btn-solid($color-warning); }
	&.btn-info { @include btn-solid($color-info); }

}


	.btn--sm {
		@include font-size(13);
		padding: 5px 15px;
	}

	.btn--lg {
		@include font-size(20);
		padding: 11px 25px;
	}

	.btn--wide {
		display: block;
		width: 100%;
	}

