.events_wrapper {

	p {
		padding: 15px;
		line-height: 1.25;
		border: 1px solid #ccc;
		background-color: white;
		font-size: 15px;

		b {
			display: block;
			font-size: 18px;
			letter-spacing: -0.0475em;
		}

		time {
			display: block;
			margin-top: 20px;
			padding-top: 10px;
			border-top: 1px dotted #cccccc;
		}
	}

	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		>p {
			display: inline-block;
			width: 49%;
		}
	}

}