@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #EEEEEE;
  color: #222222; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

.directory_page .content .welcome {
  order: 1; }

.directory_page .content .main {
  order: 2; }

.directory_page .content .sidebar {
  order: 3; }

@media (min-width: 992px) {
  .directory_page .content .row {
    position: relative; }
  .directory_page .content .welcome {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .directory_page .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .directory_page .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    position: absolute;
    top: 0;
    right: 0; } }

.directory_page .content.--has-featured .welcome {
  order: 1; }

.directory_page .content.--has-featured .sidebar {
  order: 2; }

.directory_page .content.--has-featured .main {
  order: 3; }

@media (min-width: 768px) {
  .directory_page .content.--has-featured .listing_wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap; }
  .directory_page .content.--has-featured .widget_register {
    width: 100%; }
  .directory_page .content.--has-featured .list-listings {
    width: 100%; } }

@media (min-width: 992px) {
  .directory_page .content.--has-featured .welcome {
    margin-left: auto; }
  .directory_page .content.--has-featured .main {
    margin-left: auto; }
  .directory_page .content.--has-featured .sidebar {
    right: auto;
    left: 0; }
  .directory_page .content.--has-featured .listing_wrapper {
    display: flex;
    flex-wrap: wrap; }
  .directory_page .content.--has-featured .page_directory_menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 190px; } }

@media (min-width: 1200px) {
  .directory_page .content.--has-featured .page_directory_menu {
    width: 230px; } }

@media (min-width: 992px) {
  body.page_front .content .main {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  body.page_front .content .sidebar {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1200px) {
  body.page_front .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  body.page_front .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (max-width: 991px) {
  body.page .content .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  body.page .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  body.page .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

body {
  min-width: 360px;
  background-color: #EEEEEE; }
  @media (min-width: 768px) {
    body:not(.page_front) {
      height: 100%;
      padding-bottom: 48px; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.64706em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h5, .h5,
h6, .h6, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -0.0375em;
  line-height: 1em; }
  h5 small, .h5 small, h6 small, .h6 small, h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 18px;
      font-size: 1.125rem; } }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 24px;
      font-size: 1.5rem; } }

h2, .h2 {
  font-size: 24px;
  font-size: 1.5rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 27px;
      font-size: 1.6875rem; } }

h1, .h1 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 32px;
      font-size: 2rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 120%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #222222; }
  blockquote.bq--primary {
    border-color: #F15B5C; }
  blockquote.bq--secondary {
    border-color: #7270A5; }
  blockquote.bq--highlight {
    border-color: #ff8400; }
  blockquote.bq--accent {
    border-color: #0c84ea; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #222222; }
  .bq--primary .bq--title {
    color: #F15B5C; }
  .bq--secondary .bq--title {
    color: #7270A5; }
  .bq--highlight .bq--title {
    color: #ff8400; }
  .bq--accent .bq--title {
    color: #0c84ea; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #222222;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #F15B5C; }
  blockquote.bq-alt.bq--secondary {
    border-color: #7270A5; }
  blockquote.bq-alt.bq--highlight {
    border-color: #ff8400; }
  blockquote.bq-alt.bq--accent {
    border-color: #0c84ea; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #0c84ea; }
  a:hover, a:focus {
    color: #349cf5;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #bfbed6; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .nav, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list-spaced li:not(:last-child) {
  margin-bottom: 10px; }

@media (min-width: 768px) {
  .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .list-wrap .list {
      width: auto;
      margin-right: 20px; } }

.list-directory strong {
  font-size: 120%; }

.list-directory li {
  margin: 0 !important;
  padding: 15px; }
  .list-directory li:nth-child(even) {
    border: 1px solid #dbdbdb;
    background-color: #fff; }
  .list-directory li p {
    margin-bottom: 0; }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

/* .icon-12 { width: 12px; height: 12px; }
.icon-16 { width: 16px; height: 16px; }

.icon-18 { width: 18px; height: 18px; }
.icon-64 { width: 64px; height: 64px; }*/
.icon-primary {
  fill: #F15B5C; }

.icon-secondary {
  fill: #7270A5; }

.icon-star {
  fill: #ffc107; }

.icon-rating,
.icon-white {
  fill: #fff; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 55%;
    margin: 0 0 15px 20px;
    max-width: none; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .img--right {
    width: 50%; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.btn {
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
  padding: 8px 20px;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #F15B5C;
  border-radius: 60px;
  background-color: #EEEEEE; }
  .btn:hover {
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    background-color: #F15B5C;
    color: #fff;
    cursor: pointer; }
  .btn.wbg-fix {
    background: #fff; }
  .btn.btn-primary {
    border-color: #F15B5C;
    background-color: transparent;
    color: #F15B5C; }
    .btn.btn-primary:hover {
      background-color: #F15B5C;
      color: #fff; }
  .btn.btn-secondary {
    border-color: #7270A5;
    background-color: transparent;
    color: #7270A5; }
    .btn.btn-secondary:hover {
      background-color: #7270A5;
      color: #fff; }
  .btn.btn-highlight {
    border-color: #ff8400;
    background-color: transparent;
    color: #ff8400; }
    .btn.btn-highlight:hover {
      background-color: #ff8400;
      color: #fff; }
  .btn.btn-accent {
    border-color: #0c84ea;
    background-color: transparent;
    color: #0c84ea; }
    .btn.btn-accent:hover {
      background-color: #0c84ea;
      color: #fff; }
  .btn.btn-success {
    border-color: #28a745;
    background-color: transparent;
    color: #28a745; }
    .btn.btn-success:hover {
      background-color: #28a745;
      color: #fff; }
  .btn.btn-danger {
    border-color: #dc3545;
    background-color: transparent;
    color: #dc3545; }
    .btn.btn-danger:hover {
      background-color: #dc3545;
      color: #fff; }
  .btn.btn-warning {
    border-color: #ffc107;
    background-color: transparent;
    color: #ffc107; }
    .btn.btn-warning:hover {
      background-color: #ffc107;
      color: #fff; }
  .btn.btn-info {
    border-color: #0182C8;
    background-color: transparent;
    color: #0182C8; }
    .btn.btn-info:hover {
      background-color: #0182C8;
      color: #fff; }

.btn--sm {
  font-size: 13px;
  font-size: 0.8125rem;
  padding: 5px 15px; }

.btn--lg {
  font-size: 20px;
  font-size: 1.25rem;
  padding: 11px 25px; }

.btn--wide {
  display: block;
  width: 100%; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

.btn-mobile {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #7270A5;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .btn-mobile:hover, .btn-mobile:focus {
    outline: none;
    background-color: #918fb9;
    cursor: pointer; }
  .btn-mobile b {
    display: none; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.phone {
  text-decoration: none;
  color: #F15B5C; }
  .phone:hover {
    cursor: default;
    color: #F15B5C; }

.contact-call {
  line-height: 1em; }

.banner-call {
  margin-bottom: 0; }

.contact-title {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.0325em;
  line-height: 1em; }

.contact-subtitle {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.35em; }

.banner-phone {
  display: block;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0475em; }

.events_wrapper p {
  padding: 15px;
  line-height: 1.25;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 15px; }
  .events_wrapper p b {
    display: block;
    font-size: 18px;
    letter-spacing: -0.0475em; }
  .events_wrapper p time {
    display: block;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px dotted #cccccc; }

@media (min-width: 768px) {
  .events_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .events_wrapper > p {
      display: inline-block;
      width: 49%; } }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px 15px 7px;
  border: 1px solid #ccc; }
  .form_section .section-title {
    padding: 5px 45px 5px 10px;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.0475em; }
  .form_section .section-subtitle {
    margin: 10px 0;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: none;
    letter-spacing: -0.0475em;
    color: #222222; }
  .form_section hr {
    margin: 15px 0 20px;
    background-color: #c5c5c5; }

.form-title {
  margin: 10px 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  position: relative;
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 5px;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_radio,
.form_checkbox {
  vertical-align: top;
  line-height: 1em; }
  .form_radio label,
  .form_checkbox label {
    display: block;
    clear: both;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_radio .checkbox, .form_radio .radio,
  .form_checkbox .checkbox,
  .form_checkbox .radio {
    display: block;
    margin: 0 15px 0 0;
    float: left;
    width: 20px;
    height: 20px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 68px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -5px;
    left: -19px;
    -webkit-transform: scale(0.87);
    -moz-transform: scale(0.87);
    -o-transform: scale(0.87);
    transform: scale(0.87); }

@media (min-width: 768px) and (max-width: 991px) {
  .featured_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center; }
    .featured_wrapper > div {
      width: 49%; }
      .featured_wrapper > div:nth-child(even) {
        margin-left: auto; } }

.abox_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0; }
  @media (max-width: 767px) {
    .abox_wrapper .abox {
      width: 48%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .abox_wrapper .abox {
      width: 32%; } }
  @media (min-width: 992px) {
    .page_front .abox_wrapper .abox {
      width: 48%; } }

.abox {
  margin: 0 0 2%;
  padding: 15px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-align: center;
  border: 1px solid #dbdbdb;
  background-color: #fff; }
  .abox img {
    display: block;
    width: auto;
    max-height: 100px;
    margin: 0 auto; }
  .abox .abox-title {
    display: block;
    margin: 5px 0;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1em;
    letter-spacing: -0.0275em; }
    .abox .abox-title small {
      display: block; }
  .abox .phone {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1em;
    font-weight: 800;
    letter-spacing: -0.0475em;
    color: #F15B5C !important; }
  .abox p {
    margin-top: 10px;
    line-height: 1.275em; }
  .abox > *:last-child {
    margin-bottom: 0 !important; }

.a--spec {
  max-width: 370px;
  position: relative;
  margin: 0 auto 15px;
  padding: 15px;
  background-color: white; }
  .a--spec .abox-bg {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0; }
  .a--spec .abox-logo {
    display: inline-block;
    margin: 0 0 0 auto;
    width: 100%;
    max-width: 150px; }
  .a--spec .ad-details {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 30px 30px;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.925); }
    .a--spec .ad-details > div {
      width: 100%; }
    .a--spec .ad-details .d-right {
      padding-left: 30px;
      text-align: right; }
  .a--spec .phone {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 1em;
    font-weight: 800;
    letter-spacing: -0.0475em;
    color: #F15B5C !important;
    white-space: nowrap; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .a--spec .ad-details .d-right img {
      min-width: 100px; } }

.a--spec_alt {
  padding: 0;
  border-radius: 6px;
  border: 5px solid #cccccc;
  text-align: center; }
  .a--spec_alt .ad-top {
    position: relative; }
  .a--spec_alt .top-img {
    width: 100%;
    border-radius: 3px 3px 0 0; }
  .a--spec_alt .logo-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 8px 15px;
    text-align: center;
    line-height: 0; }
    .a--spec_alt .logo-wrapper img {
      display: inline-block;
      height: 72px; }
  .a--spec_alt .ad-details {
    position: relative;
    text-align: left;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 15px; }
    .a--spec_alt .ad-details p {
      margin-bottom: 0;
      display: block;
      width: 100%;
      white-space: nowrap; }
    .a--spec_alt .ad-details .call {
      text-align: right; }
      .a--spec_alt .ad-details .call .phone {
        font-size: 21px;
        font-size: 1.3125rem; }
      .a--spec_alt .ad-details .call small {
        color: #222222;
        font-weight: 600; }

.--spec_pgs {
  border-color: #2EC8B3; }
  .--spec_pgs .logo-wrapper {
    padding-bottom: 10px;
    background-color: rgba(32, 68, 128, 0.8); }
  .--spec_pgs .phone {
    color: #2EC8B3 !important; }
  .--spec_pgs .btn {
    border-color: #204480;
    background-color: transparent;
    color: #204480; }
    .--spec_pgs .btn:hover {
      background-color: #204480;
      color: #fff; }

.--spec_atrek {
  border-color: #2432C5; }
  .--spec_atrek .logo-wrapper {
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.75); }
  .--spec_atrek .phone {
    color: #2432C5 !important; }
  .--spec_atrek .btn {
    border-color: #539429;
    background-color: transparent;
    color: #539429; }
    .--spec_atrek .btn:hover {
      background-color: #539429;
      color: #fff; }

.list-listings li {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 !important;
  padding: 15px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em; }
  .list-listings li:nth-child(even) {
    border: 1px solid #dbdbdb;
    background-color: #fff; }

.list-listings .address {
  width: 65%;
  margin: 0; }

.list-listings strong {
  display: block;
  margin-bottom: 8px;
  font-size: 17px;
  font-size: 1.0625rem; }

.list-listings .contact-wrapper {
  width: 35%;
  margin: 0;
  text-align: right; }

.list-listings .phone {
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 800;
  letter-spacing: -0.0375em;
  color: #222222; }

.list-listings .category-wrapper {
  display: block;
  margin-top: 5px; }

.list-listings .category {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1em;
  border-radius: 30px;
  background-color: #7270A5;
  color: #fff; }

@media (min-width: 1200px) {
  .list-listings .address br {
    display: none; } }

.list-listings > li.featured {
  border-left: 5px solid #918fb9 !important;
  margin-bottom: 10px !important;
  font-weight: 600; }
  .list-listings > li.featured a:not(.phone)::after {
    position: relative;
    display: inline-block;
    content: " \259F";
    vertical-align: top;
    top: -0.04em;
    -webkit-transform: scale(0.5) rotate(-45deg);
    -moz-transform: scale(0.5) rotate(-45deg);
    -o-transform: scale(0.5) rotate(-45deg);
    transform: scale(0.5) rotate(-45deg); }
  .list-listings > li.featured .category {
    background-color: #F15B5C; }
  .list-listings > li.featured:nth-of-type(2n) {
    border-left-color: #59578b !important; }

.branding-title {
  margin: 0;
  font-size: 46px;
  font-size: 2.875rem;
  font-weight: normal;
  line-height: 1em; }

.logo {
  display: block;
  margin: 0 auto;
  line-height: 0;
  text-align: center;
  text-decoration: none;
  color: #F15B5C !important;
  white-space: nowrap; }
  .logo strong {
    display: block;
    font-size: 36px;
    font-size: 2.25rem;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1em;
    word-spacing: -0.2em; }
  .logo small {
    display: inline-block;
    padding: 5px 20px 3px;
    font-size: 17px;
    font-size: 1.0625rem;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 8px;
    border-radius: 100px;
    background-color: #7270A5;
    color: #fff; }

.map {
  margin: 0 0 1em;
  border: 4px solid #fff; }

.nav {
  margin: 0;
  padding: 0; }

.nav-item {
  box-sizing: border-box;
  list-style-type: none; }
  .nav-item.active .nav-link {
    cursor: default; }
    .nav-item.active .nav-link:hover {
      cursor: default; }
  .nav-item.open .dropdown-nav {
    display: block;
    height: auto; }
  .nav-item.open .caret {
    transform: rotate(180deg); }

.nav-link {
  transition: all 400ms;
  display: block;
  padding: 12px 15px;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    transition: all 400ms;
    outline: none; }
    .nav-link:hover .caret-push, .nav-link:focus .caret-push {
      width: 0.75em;
      transition: width 400ms; }
    .nav-link:hover .caret, .nav-link:focus .caret {
      transition: fill 400ms; }

.caret,
.caret-push {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 0.75rem;
  height: 0.75rem; }

.caret {
  transition: fill 400ms; }

.caret-push {
  transform: rotate(-90deg);
  transition: width 400ms;
  width: 0; }

.dropdown-nav {
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 10;
  margin: 0; }
  .dropdown-nav .nav-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.nav_main {
  position: fixed;
  z-index: 10;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 80%;
  max-width: 300px;
  height: 100%;
  padding: 20px;
  text-align: left;
  background-color: #7270A5;
  color: white; }
  @media (max-width: 767px) {
    .nav_main {
      display: none; }
      .nav_main.in {
        display: block; } }
  .nav_main .nav-item.active .nav-link {
    font-weight: 800;
    letter-spacing: -0.0325em;
    text-shadow: none !important;
    background-color: white;
    color: #F15B5C; }
    .nav_main .nav-item.active .nav-link .caret,
    .nav_main .nav-item.active .nav-link .caret-push {
      width: 0; }
    .nav_main .nav-item.active .nav-link:hover, .nav_main .nav-item.active .nav-link:focus {
      background-color: white;
      color: #F15B5C; }
  .nav_main .nav-item.open > .nav-link {
    background-color: #F15B5C;
    color: white; }
    .nav_main .nav-item.open > .nav-link .caret {
      fill: white; }
  .nav_main .nav-link {
    padding: 15px;
    font-weight: 600;
    background-color: transparent;
    color: white; }
    .nav_main .nav-link:hover, .nav_main .nav-link:focus, .nav_main .nav-link:active {
      outline: none;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      background-color: #F15B5C;
      color: white; }
      .nav_main .nav-link:hover .caret,
      .nav_main .nav-link:hover .caret-push, .nav_main .nav-link:focus .caret,
      .nav_main .nav-link:focus .caret-push, .nav_main .nav-link:active .caret,
      .nav_main .nav-link:active .caret-push {
        fill: white; }
  .nav_main .caret {
    fill: white; }
  .nav_main .caret-push {
    fill: white; }
  .nav_main .dropdown-nav {
    padding: 0;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.1); }
  .nav_main hr {
    margin: 15px 0;
    background-color: white; }
  @media (min-width: 768px) {
    .nav_main {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      overflow-y: visible;
      width: 100%;
      max-width: none;
      height: auto;
      padding: 0;
      border-right: none;
      box-shadow: none;
      background-color: transparent; }
      .nav_main hr {
        display: none; }
      .nav_main .nav-item.active .nav-link {
        background: #EEEEEE; }
        .nav_main .nav-item.active .nav-link b {
          font-weight: 800; }
        .nav_main .nav-item.active .nav-link:hover, .nav_main .nav-item.active .nav-link:focus {
          background: #EEEEEE; }
      .nav_main .nav-item.open > .nav-link {
        background-color: #F15B5C; }
      .nav_main .nav-link {
        border-color: #59578b;
        background-color: #7270A5;
        color: white; }
        .nav_main .nav-link:hover, .nav_main .nav-link:focus {
          background-color: #F15B5C;
          color: white; }
      .nav_main .dropdown-nav {
        border: 1px solid #dddddd;
        background-color: white; }
        .nav_main .dropdown-nav .nav-item.active .nav-link {
          background-color: white !important;
          color: #F15B5C !important; }
        .nav_main .dropdown-nav .nav-link {
          background-color: transparent;
          color: #222222; }
          .nav_main .dropdown-nav .nav-link:hover {
            background-color: transparent;
            color: #F15B5C;
            text-shadow: none !important; }
          .nav_main .dropdown-nav .nav-link .caret-push {
            fill: #F15B5C; } }

.mobile-toggle {
  display: inline-block;
  padding: 5px 15px;
  color: white !important;
  text-decoration: none; }
  .mobile-toggle .icon-close {
    margin-right: 5px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    padding: 5px;
    background-color: #dc3545;
    fill: white; }
  @media (min-width: 768px) {
    .mobile-toggle {
      display: none; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

@media (max-width: 767px) {
  .slideshow .slick-prev,
  .slideshow .slick-next {
    visibility: hidden; } }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.weather-current {
  margin: 0 0 30px;
  padding: 15px;
  border-radius: 6px;
  border: 3px solid #7270A5;
  background-color: rgba(255, 255, 255, 0.7); }
  @media (min-width: 576px) {
    .weather-current {
      overflow: hidden; }
      .weather-current .weather-quick {
        float: left;
        width: 40%;
        margin-bottom: 0;
        padding: 15px 15px 0 0; }
      .weather-current .weather-detail {
        float: right;
        width: 60%; } }
  @media (min-width: 768px) {
    .weather-current .weather-quick {
      padding-top: 0;
      width: 35%; }
    .weather-current .weather-detail {
      width: 65%; } }

.weather-quick {
  margin-bottom: 15px;
  text-align: center; }
  .weather-quick strong {
    display: block;
    font-size: 96px;
    line-height: 1em;
    letter-spacing: -6px; }
    .weather-quick strong sup {
      font-weight: 600; }
      .weather-quick strong sup small {
        display: inline-block;
        margin-left: 5px;
        font-size: 60%; }
  .weather-quick .feels-like {
    display: block;
    font-size: 115%; }
  .weather-quick img {
    max-width: 150px;
    margin: 0 auto; }
  .weather-quick .img-caption {
    font-size: 115%;
    line-height: 1em;
    font-style: italic; }

.weather-table {
  margin: 0;
  font-size: 85%; }

.weather-forecast {
  padding: 15px 0; }
  .weather-forecast .forecast {
    padding: 5px; }
  .weather-forecast .forecast-1 {
    display: none; }
  .weather-forecast .forecast_wrapper {
    padding: 15px;
    font-size: 14px;
    line-height: 1em;
    text-align: center;
    border-radius: 6px;
    border: 3px solid #7270A5;
    background-color: rgba(255, 255, 255, 0.7); }
    .weather-forecast .forecast_wrapper:not(:last-child) {
      margin-bottom: 15px; }
    .weather-forecast .forecast_wrapper h3 {
      margin: 0 0 5px;
      font-size: 115%;
      line-height: 1em; }
    .weather-forecast .forecast_wrapper img {
      max-width: 150px;
      margin: 0 auto 15px; }
    .weather-forecast .forecast_wrapper .forecast-temp {
      font-size: 18px;
      font-weight: 600; }
    .weather-forecast .forecast_wrapper .forecast-summary {
      text-transform: capitalize; }
  @media (min-width: 768px) {
    .weather-forecast {
      display: flex;
      flex-wrap: wrap; }
      .weather-forecast .forecast {
        width: 50%; } }
  @media (min-width: 992px) {
    .weather-forecast .forecast {
      width: 25%; } }

.weather-credit {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 2px solid #F05514;
  border-bottom: 2px solid #F05514; }
  .weather-credit p {
    font-size: 17px;
    font-weight: 800;
    letter-spacing: -0.0375em;
    text-transform: uppercase;
    margin: 0;
    text-align: center; }
    .weather-credit p img {
      display: inline-block;
      max-width: 270px;
      margin: 0 auto; }

.widget {
  margin-bottom: 15px;
  padding: 20px 20px 25px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.275em;
  border-radius: 8px;
  border: 2px solid #ccc;
  background-color: #fff; }
  .widget.widget-primary {
    border-color: #F15B5C; }
    .widget.widget-primary .widget-title {
      color: #F15B5C; }
  .widget.widget-secondary {
    border-color: #7270A5; }
    .widget.widget-secondary .widget-title {
      color: #7270A5; }
  .widget.widget-highlight {
    border-color: #ff8400; }
    .widget.widget-highlight .widget-title {
      color: #ff8400; }
  .widget.widget-accent {
    border-color: #0c84ea; }
    .widget.widget-accent .widget-title {
      color: #0c84ea; }
  .widget > *:first-child {
    margin-top: 0; }
  .widget > *:last-child {
    margin-bottom: 0; }

.widget-title {
  margin: 0 0 15px;
  letter-spacing: -0.0375em; }

.widget-links li {
  line-height: 1em; }

.widget-links li:not(:last-child) {
  border-bottom: 1px dotted #bbbbbb; }

.widget-links a {
  display: block;
  padding: 10px;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none !important; }
  .widget-links a em {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: normal;
    color: #222222; }
  .widget-links a:hover {
    background-color: #0c84ea;
    color: white; }

.widget_menu .menu_item:not(:last-child) {
  border-bottom: 1px dotted rgba(34, 34, 34, 0.4); }

.widget_menu .menu_item.active > .menu_link {
  color: #F15B5C;
  font-weight: 800; }
  .widget_menu .menu_item.active > .menu_link .caret-push {
    width: 0;
    margin-right: 0; }

.widget_menu .menu_link {
  padding: 10px 2px;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #222222; }
  .widget_menu .menu_link:hover, .widget_menu .menu_link:focus {
    background-color: #fff;
    color: #0c84ea; }
    .widget_menu .menu_link:hover .caret-push, .widget_menu .menu_link:focus .caret-push {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      width: 12px;
      margin-right: 3px; }
  .widget_menu .menu_link .caret-push {
    fill: #0c84ea; }

.widget_review {
  border: 3px solid #ffc107;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.block {
  padding: 30px 15px; }

.block-title {
  margin: 0 0 15px; }

.front_fold {
  line-height: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.8)), url("/_/images/layout/jumbo-bg-1.jpg");
  background-position: center bottom;
  background-size: cover; }
  @media (min-width: 768px) {
    .front_fold {
      background-image: url("/_/images/layout/jumbo-bg-1.jpg"); } }
  .front_fold .block_banner .wrap + .wrap {
    margin-top: 15px; }
  @media (min-width: 768px) {
    .front_fold .block_banner {
      border-bottom: 5px solid #7270A5;
      background-color: rgba(255, 255, 255, 0.85); }
      .front_fold .block_banner .wrap + .wrap {
        margin-top: 0; }
      .front_fold .block_banner .row {
        align-items: center; }
      .front_fold .block_banner .wrap {
        flex: 0 0 25%;
        max-width: 25%; }
      .front_fold .block_banner .banner_branding {
        flex: 0 0 50%;
        max-width: 50%;
        order: 2;
        padding: 0; }
      .front_fold .block_banner .banner_date {
        order: 1;
        text-align: left; }
      .front_fold .block_banner .banner_weather {
        order: 3;
        text-align: right; } }
  @media (min-width: 768px) {
    .front_fold .banner_navigation {
      margin-top: 180px; } }
  @media (min-width: 992px) {
    .front_fold .banner_navigation {
      margin-top: 280px; } }
  @media (min-width: 1200px) {
    .front_fold .banner_navigation {
      margin-top: 320px; } }

.block_banner {
  line-height: 1;
  text-align: center; }

.banner_button {
  text-align: left; }

.branding-logo {
  display: block;
  margin: 0 auto;
  max-width: 300px; }
  @media (min-width: 992px) {
    .branding-logo {
      max-width: 360px; } }

.info_date {
  font-weight: 600;
  letter-spacing: -0.0375em; }
  .info_date .day {
    display: block; }
  .info_date .date {
    display: block; }
  .info_date .current-time b {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800; }

.info_weather .to_weather {
  margin-bottom: 5px; }

.info_weather .temp {
  display: block;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 800; }
  .info_weather .temp sup {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600; }

.info_weather .accuweather {
  display: inline-block;
  max-width: 150px;
  padding: 5px;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none;
  color: #222222 !important; }

.banner_navigation {
  margin-top: -1px;
  padding: 0;
  line-height: 0; }
  @media (min-width: 768px) {
    .banner_navigation {
      margin-top: 0;
      background-color: #7270A5; }
      .banner_navigation .nav-main {
        text-align: center; }
        .banner_navigation .nav-main > .nav-item {
          display: inline-block; }
          .banner_navigation .nav-main > .nav-item:not(:last-child) {
            border-right: 1px solid white; }
          .banner_navigation .nav-main > .nav-item:not(:first-child) {
            border-left: 1px solid white; }
          .banner_navigation .nav-main > .nav-item > .nav-link {
            padding: 20px 30px; }
      .banner_navigation .dropdown-nav {
        position: absolute;
        min-width: 270px;
        padding: 15px; }
        .banner_navigation .dropdown-nav .nav-item:not(:last-child) {
          border-bottom: 1px dotted #bbbbbb; }
        .banner_navigation .dropdown-nav .nav-link {
          padding: 10px;
          letter-spacing: -0.0325em; } }
  @media (min-width: 992px) {
    .banner_navigation .nav-main > .nav-item {
      display: block;
      float: left;
      width: 20%; } }

.update_block {
  background-color: white;
  text-align: center; }
  .update_block h2, .update_block h3 {
    margin: 0; }

.update_title {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc; }

.update-title, .update-content {
  margin-bottom: 15px; }

@media (min-width: 992px) {
  .update_card {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
    .update_card > div {
      width: 50%;
      padding: 30px; } }

@media (min-width: 1200px) {
  .update_card {
    justify-content: space-between;
    text-align: left; }
    .update_card .update-title {
      width: 480px;
      min-width: 480px;
      max-width: 480px; }
    .update_card .update-content {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center; }
    .update_card .update-convert {
      padding-top: 0; } }

@media (min-width: 1200px) {
  .update-content {
    display: flex;
    flex-wrap: nowrap; } }

.update-convert {
  padding-top: 25px; }

@media (max-width: 575px) {
  .update-list li:not(:last-child) {
    margin-bottom: 30px; } }

@media (min-width: 576px) {
  .update-list {
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
    .update-list li {
      display: inline-block; }
    .update-list li:not(:last-child) {
      margin-right: 15px; } }

.page_title {
  border-top: 2px solid white;
  color: white;
  background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), linear-gradient(135deg, #fba810 50%, #fbde37); }
  @media (min-width: 768px) {
    .page_title {
      padding: 120px 15px 15px; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 180px; } }

.page-title {
  margin: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }

.main > *:last-child {
  margin-bottom: 0; }

@media (max-width: 991px) {
  .sidebar {
    margin-top: 30px; } }

.site_info {
  padding: 15px;
  background-color: #59578b;
  color: #fff; }
  .site_info a {
    color: #fff;
    text-decoration: none; }
  .site_info .list > li:not(:last-child) {
    margin-bottom: 4px; }
  @media (min-width: 768px) {
    .site_info {
      text-align: center; }
      .site_info .list > li {
        display: inline-block;
        margin: 0 15px 0 0 !important; } }

.front_jumbo {
  padding: 0 15px; }
  .front_jumbo .container {
    padding: 50px 10px;
    border: 5px solid #fff;
    background: url("/_/images/layout/jumbo-bg-1.jpg") top center no-repeat;
    background-size: cover; }
  @media (min-width: 768px) {
    .front_jumbo .container {
      padding: 0 10px; } }

.jumbo_directory {
  width: auto;
  padding: 0;
  background-color: rgba(245, 245, 245, 0.95); }
  @media (min-width: 768px) {
    .jumbo_directory .nav-item {
      min-width: 220px; } }
  @media (min-width: 992px) {
    .jumbo_directory .nav-item {
      min-width: 250px; } }

.jumbo_marketing {
  padding: 10px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800;
  text-align: center;
  letter-spacing: -0.0325em;
  background: rgba(255, 255, 255, 0.85); }

.front_welcome {
  text-align: center; }
  @media (min-width: 992px) {
    .front_welcome .row {
      justify-content: center; }
    .front_welcome .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (min-width: 1200px) {
    .front_welcome .wrap {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.welcome_title .block-title::before {
  display: block;
  content: "Welcome to "; }

.welcome_title .block-title::after {
  content: "!"; }

@media (min-width: 768px) {
  .welcome_title .block-title::before {
    display: inline; } }

.front_guide {
  text-align: center;
  background-image: repeating-linear-gradient(135deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), repeating-linear-gradient(45deg, rgba(68, 68, 68, 0.07) 0px, rgba(68, 68, 68, 0.07) 2px, transparent 2px, transparent 4px), linear-gradient(135deg, #fba810 50%, #fbde37); }

.guide_media {
  line-height: 0; }
  .guide_media .media_wrapper {
    display: inline-block;
    position: relative;
    overflow: hidden; }
    .guide_media .media_wrapper a {
      display: block;
      border: 5px solid #222222; }
      .guide_media .media_wrapper a img {
        transition: transform 300ms; }
      .guide_media .media_wrapper a:hover img {
        transform: scale(1.3);
        transition: transform 300ms; }
    .guide_media .media_wrapper span {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      padding: 10px;
      font-size: 15px;
      font-size: 0.9375rem;
      font-weight: 600;
      line-height: 1.2;
      background-color: rgba(0, 0, 0, 0.8);
      color: white; }
  @media (min-width: 768px) {
    .guide_media {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .guide_media .media_wrapper span {
      font-size: 20px;
      font-size: 1.25rem; } }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 800 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #222222 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #F15B5C !important; }

.color--secondary {
  color: #7270A5 !important; }

.color--highlight {
  color: #ff8400 !important; }

.color--accent {
  color: #0c84ea !important; }

.color--link {
  color: #0c84ea !important; }

.color--review {
  color: #ffc107 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #222222 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #F15B5C !important; }

.bg--secondary {
  background-color: #7270A5 !important; }

.bg--highlight {
  background-color: #ff8400 !important; }

.bg--link {
  background-color: #0c84ea !important; }

.bg--review {
  background-color: #ffc107 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #EEEEEE !important; }

.gdfancybg--text {
  background: #222222;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #222222; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #F15B5C;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #F15B5C; }

.gdfancybg--secondary {
  background: #7270A5;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #7270A5; }

.gdfancybg--highlight {
  background: #ff8400;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ff8400; }

.gdfancybg--review {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
