.front_welcome {
	text-align: center;
	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }  
		.wrap { @include make-col(10); }
	}
	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(8); }
	}
}

	.welcome_title {
		.block-title {
			&::before { display: block; content: "Welcome to "; }
			&::after { content: "!"; }
		}
		@include media-breakpoint-up(md) {
			.block-title::before { display: inline; }
		}
	}