.weather-current {

	margin: 0 0 30px;
	padding: 15px;
	border-radius: 6px;
	border: 3px solid $color-secondary;
	background-color: rgba(255, 255, 255, 0.7);

	@include media-breakpoint-up(sm) {
		overflow: hidden;

		.weather-quick {
			float: left;
			width: 40%;
			margin-bottom: 0;
			padding: 15px 15px 0 0;
		}

		.weather-detail {
			float: right;
			width: 60%;
		}
	}

	@include media-breakpoint-up(md) {
		.weather-quick {
			padding-top: 0;
			width: 35%;
		}

		.weather-detail {
			width: 65%;
		}
	}

}

.weather-quick {
	margin-bottom: 15px;
	text-align: center;

	strong {
		display: block;
		font-size: 96px;
		line-height: 1em;
		letter-spacing: -6px;

		sup {
			font-weight: 600;

			small {
				display: inline-block;
				margin-left: 5px;
				font-size: 60%;
			}
		}
	}

	.feels-like {
		display: block;
		font-size: 115%;
	}

	img {
		max-width: 150px;
		margin: 0 auto;
	}

	.img-caption {
		font-size: 115%;
		line-height: 1em;
		font-style: italic;
	}
}


.weather-table {
	margin: 0;
	font-size: 85%;
}



.weather-forecast {
	padding: 15px 0;

	.forecast {
		padding: 5px;
	}

	.forecast-1 {
		display: none;
	}

	.forecast_wrapper {
		padding: 15px;
		font-size: 14px;
		line-height: 1em;
		text-align: center;
		border-radius: 6px;
		border: 3px solid $color-secondary;
		background-color: rgba(255, 255, 255, 0.7);

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		h3 {
			margin: 0 0 5px;
			font-size: 115%;
			line-height: 1em;
		}

		img {
			max-width: 150px;
			margin: 0 auto 15px;
		}

		.forecast-temp {
			font-size: 18px;
			font-weight: 600;
		}

		.forecast-summary {
			text-transform: capitalize;
		}
	}

	@include media-breakpoint-up(md) {
		display: flex;
		flex-wrap: wrap;

		.forecast {
			width: 50%;
		}
	}

	@include media-breakpoint-up(lg) {
		.forecast {
			width: 25%;
		}
	}

}


.weather-credit {
	margin: 20px 0;
	padding: 20px 0;
	border-top: 2px solid #F05514;
	border-bottom: 2px solid #F05514;

	p {
		font-size: 17px;
		font-weight: 800;
		letter-spacing: -0.0375em;
		text-transform: uppercase;
		margin: 0;
		text-align: center;

		img {
			display: inline-block;
			max-width: 270px;
			margin: 0 auto;
		}
	}
}