.featured_wrapper {
	@include media-breakpoint-only(md) {
		@include make-flex;
		flex-wrap: wrap;
		justify-content: center;
		> div {
			width: 49%;
			&:nth-child(even) { margin-left: auto; }
		}
	}
}


.abox_wrapper {
	@include make-flex;
	justify-content: space-evenly;
	padding: 0;
	@include media-breakpoint-down(sm) {
		.abox { width: 48%; }
	}
	@include media-breakpoint-only(md) {
		.abox { width: 32%; }
	}
	.page_front & {
		@include media-breakpoint-only(md) {
			//.abox:last-child { display: none; }
		}
		@include media-breakpoint-up(lg) {
			.abox { width: 48%; }
		}
	}
}


.abox {
	margin: 0 0 2%;
	padding: 15px;
	@include font-size(14);
	line-height: 1em;
	text-align: center;
	border: 1px solid darken($body-bg, 7.5%);
	background-color: $white;
	img {
		display: block;
		width: auto;
		max-height: 100px;
		margin: 0 auto;
	}
	.abox-title {
		display: block;
		//margin: 10px 0 5px;
		margin: 5px 0;
		//padding-top: 10px;
		@include font-size(16);
		line-height: 1em;
		letter-spacing: -0.0275em;
		//border-top: 1px dotted #ccc;
		small { display: block; }
	}
	.phone {
		@include font-size(17);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0475em;
		color: theme(primary, base) !important;
	}
	p {
		margin-top: 10px;
		line-height: 1.275em;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}

}


.a--spec {
	max-width: 370px;
	position: relative;
	margin: 0 auto 15px;
	padding: 15px;
	background-color: white;

	.abox-bg {
		display: block;
		width: 100%;
		max-width: 400px;
		margin: 0;
	}

	.abox-logo {
		display: inline-block;
		margin: 0 0 0 auto;
		width: 100%;
		max-width: 150px;
	}

	.ad-details {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 15px 30px 30px;
		line-height: 1;
		background-color: rgba(255, 255, 255, 0.925);

		> div { width: 100%; }
		
		.d-right {
			padding-left: 30px;
			text-align: right;
		}
	}

	.phone {
		@include font-size(17);
		line-height: 1em;
		font-weight: $body-weight-bolder;
		letter-spacing: -0.0475em;
		color: theme(primary, base) !important;
		white-space: nowrap;
	}

	@include media-breakpoint-only(lg) {
		.ad-details .d-right img {
			min-width: 100px;
		}
	}

}

.a--spec_alt {	
	padding: 0;
	border-radius: 6px;
	border: 5px solid #cccccc;
	text-align: center;
	.ad-top {
		position: relative;
	}
	.top-img { width: 100%; border-radius: 3px 3px 0 0; }
	.logo-wrapper {
		position: absolute;
		bottom: 0; left: 0; right: 0;
		width: 100%;
		padding: 8px 15px;
		text-align: center;
		line-height: 0;
		img { display: inline-block; height: 72px; }
	}
	.ad-details {
		position: relative;
		text-align: left;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: 15px;
		p { margin-bottom: 0; display: block; width: 100%; white-space: nowrap; }
		.call { 
			text-align: right;
			.phone { @include font-size(21); }
			small { color: $color-text; font-weight: $body-weight-bold; }
		}
	}
}


.--spec_pgs {
	border-color: #2EC8B3;
	.logo-wrapper {
		padding-bottom: 10px;
		background-color: rgba(#204480,0.8);
	}
	.phone { color: #2EC8B3 !important; }
	.btn {
		@include btn-solid(#204480);
	}
}

.--spec_atrek {
	border-color: #2432C5;
	.logo-wrapper {
		padding-bottom: 10px;
		background-color: rgba(#000000, 0.75);
	}
	.phone { color: #2432C5 !important; }
	.btn {
		@include btn-solid(#539429);
	}	
}